import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import {
  SiteContext,
  UnitSocketContext,
  selectIsVerificationEnabled,
  selectUser,
} from '@homeplay/app';
import BonusBannerBase from '../HappyHour/BonusBannerBase';
import BonusBanner from '../HappyHour/BonusBanner';
import GetBonusBanner from '../HappyHour/GetBonusBanner';
import InfoModal from '../HappyHour/InfoModal';
import WhyRegisterModal from '../Modal/WhyRegisterModal';
import ButtonSecondary from '../UI/ButtonSecondary';
import Balance from './Balance';
import classes from './Header.module.scss';
import { MaintenanceBanner } from '../UI/banners/MaintenanceBanner';

const MainHeader = () => {
  const { siteName } = useContext(SiteContext);
  const { acquireHappyHourBonus } = useContext(UnitSocketContext);
  const [whyRegisterModal, setWhyRegisterModal] = useState(false);
  const [happyHourBonusModal, setHappyHourBonusModal] = useState(false);
  const [bonusParams, setBonusParams] = useState(null);
  const [happyHourBonusAmount, setHappyHourBonusAmount] = useState(null);
  const user = useSelector(selectUser);
  const isVerificationEnabled = useSelector(selectIsVerificationEnabled);
  const { balance, depositBalance, bonus } = useSelector((state) => state.unit);
  const { happyHour, happyHourBonus } = useSelector(
    (state) => state.promotions
  );
  const navigate = useNavigate();

  useEffect(() => {
    setBonusParams(getBonusParams(happyHourBonus.bonusStates));
  }, [happyHourBonus.bonusStates]);

  useEffect(() => {
    if (user && happyHourBonus.isComplete) {
      const bonusAmount = happyHourBonus.bonusStates.reduce((sum, state) => {
        if (state.complete) {
          return sum + state.amount;
        }
        return sum;
      }, 0);
      setHappyHourBonusAmount(bonusAmount);
    }
  }, [happyHourBonus.fetched]);

  useEffect(() => {
    if (!happyHour && happyHourBonusModal) {
      setHappyHourBonusModal(false);
    }
  }, [happyHour]);

  const handleLoadFundsClick = () => {
    if (!!user) {
      navigate(`/${siteName ? siteName + '/' : ''}cashin`);
    } else {
      setWhyRegisterModal(true);
    }
  };

  const handleClickHappyHour = () => {
    if (user) {
      setHappyHourBonusModal(true);
    } else {
      navigate(`/${siteName ? siteName + '/' : ''}auth`);
    }
  };

  const getBonusParams = (bonusStates) => {
    if (user && bonusStates) {
      const bonusState = bonusStates.find((state) => state.isDeposited);
      if (bonusState && bonusState.end > Date.now()) {
        return {
          countDownDate: bonusState.end,
          challengeBalance: bonusState.balance,
        };
      }
    }
    return null;
  };

  const fetchUnitBonus = () => {
    if (!happyHourBonus.isComplete) return;
    happyHourBonus.bonusStates
      .filter((state) => state.complete)
      .forEach((state) => {
        acquireHappyHourBonus(state.id);
      });
  };

  let button;
  if (user) {
    button = (
      <ButtonSecondary
        navPath={`/${siteName ? siteName + '/' : ''}profile`}
        value={'Profile'}
      />
    );
  } else if (balance + depositBalance === 0) {
    button = (
      <ButtonSecondary
        navPath={`/${siteName ? siteName + '/' : ''}auth`}
        value="Login/Register"
      />
    );
  } else {
    button = (
      <ButtonSecondary
        navPath={`/${siteName ? siteName + '/' : ''}cashout`}
        value="Cashout"
      />
    );
  }

  return (
    <>
      {user ? (
        <>
          {(happyHour || bonusParams) && (
            <CSSTransition
              in={happyHourBonusModal}
              timeout={0}
              classNames="modal"
              unmountOnExit
            >
              {bonusParams ? (
                <InfoModal
                  onClickClose={() => setHappyHourBonusModal(false)}
                  amount={bonusParams.challengeBalance}
                />
              ) : (
                <BonusBanner
                  onClickClose={() => setHappyHourBonusModal(false)}
                  minAmount={happyHour.minDeposit}
                  bonusAmount={Math.floor(
                    (happyHour.minDeposit * happyHour.bonusPercentage) / 100
                  )}
                />
              )}
            </CSSTransition>
          )}
        </>
      ) : (
        <CSSTransition
          in={whyRegisterModal}
          timeout={0}
          classNames="modal"
          unmountOnExit
        >
          <WhyRegisterModal
            onClose={() => setWhyRegisterModal(false)}
            isHappyHour={!!happyHour}
          />
        </CSSTransition>
      )}

      <header
        data-version="1110241644"
        className={
          classes.Header +
          (!happyHourBonus.isComplete && (happyHour || bonusParams)
            ? ' happy-hour'
            : '')
        }
      >
        <div className={`${classes.HeaderWrapper} header-wrapper`}>
          {button}
          <Balance
            user={user}
            balance={balance}
            depositBalance={depositBalance}
            bonus={bonus}
          />
          <ButtonSecondary onClick={handleLoadFundsClick} value="Load Funds" />
        </div>

        {!happyHourBonus.isComplete && (happyHour || bonusParams) && (
          <BonusBannerBase
            onClick={handleClickHappyHour}
            bonusParams={bonusParams}
          />
        )}
        {user && (
          <CSSTransition
            in={!!happyHourBonusAmount}
            timeout={0}
            classNames="modal"
            unmountOnExit
          >
            <GetBonusBanner
              bonus={happyHourBonusAmount}
              fetchUnitBonus={fetchUnitBonus}
              onClose={() => setHappyHourBonusAmount(null)}
            />
          </CSSTransition>
        )}
      </header>

      {isVerificationEnabled && <MaintenanceBanner />}
    </>
  );
};

export default MainHeader;
